body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
#root {
  min-height: 100vh;
}

/*pages tabs*/
.ant-tabs-top > .ant-tabs-nav::before {
  display: none !important;
}

.ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  display: none;
}

.ant-tabs .ant-tabs-tab {
  font-size: 14px;
  color: #362e35;
}

.ant-tabs .ant-tabs-tab + .ant-tabs-tab {
  margin-left: 24px;
}

.ant-tabs .ant-tabs-tab-active {
  background-color: #e3dde3;
  border-radius: 20px;
  padding: 9px 12px;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #362e35;
  font-weight: 600;
}
