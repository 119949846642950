.footer-links {
  display: inline;
}

.separator {
  display: inline;
}

@media (max-width: 600px) {
  .separator:nth-of-type(2) {
    display: none;
  }
  .terms-link {
    display: block;
    margin-top: 10px;
  }
}
