.root {
  min-width: 316px !important;
  max-width: 316px !important;
  flex: 0 0 316px !important;
  width: 316px !important;
}

.root_collapsed {
  min-width: fit-content !important;
  max-width: fit-content !important;
  flex: unset !important;
  width: fit-content !important;
}

.layout_sidebar {
  background-color: var(--primary-color);
  padding: 29px 25px;
  font-size: 15px !important;
}

.layout_sidebar :global(.ant-menu-item) {
  border-radius: 20px;
  color: white;
  opacity: 0.8;
  height: 44px;
  position: relative;
}

.layout_sidebar :global(.ant-menu-item:hover) {
  color: white !important;
  opacity: 1 !important;
  background-color: transparent !important;
}

.layout_sidebar :global(.ant-menu-item:hover::after) {
  opacity: 1 !important;
  transform: translateY(-75%) translateX(0) !important;
}

.layout_sidebar :global(.ant-menu-item::after) {
  position: absolute;
  top: 50% !important;
  right: 15px !important;
  transform: translateY(-75%) translateX(-45px) !important;
  content: url('./assets/arrow.png') !important;
  opacity: 0 !important;
  transition: all 0.8s ease-in-out;
}

.layout_sidebar :global(.ant-menu-item-selected) {
  opacity: 1;
  background-color: rgba(255, 255, 255, 0.15);
}

.layout_sidebar :global(.ant-menu-item-selected::after) {
  opacity: 1 !important;
  transform: translateY(-75%) translateX(0) !important;
}

.democard_container {
  padding: 0 50px;
  /* border: 1px solid black;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 12px; */
}

.democard_desc span {
  display: flex;
  flex-direction: column;
  color: white;
}
.democard_desc span {
  line-height: 1.2;
}

.democard_desc :nth-child(1) {
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 400;
}

.democard_desc :nth-child(2),
.democard_desc :nth-child(3) {
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 900;
}

.democard_btn {
  margin-top: 25px;
  border-radius: 20px;
  padding: 10px 30px;
  border: none;
  font-size: 16px;
  font-weight: 900;
  color: var(--primary-color);
  cursor: pointer;
}

@media (max-width: 1440px) {
  .root {
    min-width: 250px !important;
    max-width: 250px !important;
    flex: 0 0 250px !important;
    width: 250px !important;
  }

  .democard_desc :nth-child(1) {
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 400;
  }

  .democard_desc :nth-child(2),
  .democard_desc :nth-child(3) {
    text-transform: uppercase;
    font-size: 20px;
    font-weight: 900;
  }
}

.banner {
  width: 250px;
  /* height: 250px; */
  background-color: var(--primary-color);
}

/* Start Drawer Mobile*/
.drawer_body {
  padding: 0 !important;
}

@media (max-width: 425px) {
  .drawer_mobile {
    width: 290px !important;
  }
}
/* End Drawer Mobiler*/

@media (max-width: 1024px) {
  .root {
    display: none;
  }
}

.root_collapsed {
  min-width: 0 !important;
  max-width: 0 !important;
  flex: unset !important;
  width: 0 !important;
}
