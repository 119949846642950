.arrow {
  transition: transform 0.3s;
}

.collapsedButton {
  transform: rotate(180deg);
}

.side_layout {
  margin-left: 316px;
  transition: all 0.2s;
}

.site_layout_collapsed {
  margin-left: 0px !important;
}

@media (max-width: 1440px) {
  .side_layout {
    margin-left: 250px;
  }
}

@media (max-width: 1024px) {
  .side_layout {
    margin-left: 0;
  }
}
