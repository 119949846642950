:root {
  --primary-color: #755271;
  --secondary-color: #362e35;
  --auxiliary-color1: #51b55e;
  --auxiliary-color2: #e0d28b;
  --auxiliary-color3: #699ecf;
  --auxiliary-color4: #b5516c;
  --text-title: #362e35;
  --text-defaut1: #7a7579;
  --text-defaut2: #a7a2a6;
  --background-color1: #ffffff;
  --background-color2: #f5f3f5;
  --background-color3: #f4f6f4;
}
